<template>
  <en-drawer :model-value="modelValue" :title="`${form.data.id ? '编辑' : '新建'}检测单`" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="检测方案" prop="inspectionPlan.id">
        <select-maintain
          v-model="form.data.inspectionPlan"
          :ajax="{
            action: 'GET /enocloud/common/vehicle/inspection/plan',
            params: (params, value) => (params.payload = { enableCode: 'Y', planName: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          class="w-full"
          remote
          @change="form.inspectionPlan.change"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="检测日期" prop="inspectDate">
        <en-date-picker v-model="form.data.inspectDate" class="w-full"></en-date-picker>
      </en-form-item>
      <en-form-item label="检测人" prop="inspector">
        <select-maintain
          v-model="form.data.inspector"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, value) => (params.payload = { name: value }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="备注">
        <en-input v-model="form.data.comment"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudCommonDefinitions['VehicleInspectionReportDto']>,
    vehicle: Object as PropType<Record<string, any> | undefined>,
    customer: Object as PropType<Record<string, any> | undefined>,
    vehicleServiceGroup: Object as PropType<EnocloudServiceDefinitions['VehicleServiceGroupDto'] | undefined>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value: number | undefined) => ['number', 'undefinded'].includes(typeof value)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.data?.id) {
          this.form.init()
          this.form.data.id = this.data.id
          this.form.get()
        } else if (this.vehicle?.id && this.customer?.id) {
          this.form.init()
          this.form.data.vehicle = { ...this.vehicle } as EnocloudCommonDefinitions['VehicleDto']
          this.form.data.vehicleServiceGroup = this.vehicleServiceGroup
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          'inspectionPlan.id': [{ required: true, message: '请选择检测方案' }],
          'inspector.id': [{ required: true, message: '请选择检测人' }],
          inspectDate: [{ required: true, message: '请选择检测日期' }]
        },
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/inspection/report/:reportId',
            data: 'object',
            init: true,
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/common/vehicle/inspection/report',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/common/vehicle/inspection/report',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          inspectionPlan: {
            async change() {
              const res = await this.ajax('GET /enocloud/common/vehicle/inspection/plan/:planId', { paths: [this.form.data.inspectionPlan?.id] })
              this.form.data.reportItems = res.data[0].inspectionPlanItems
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.footer.cancel.click()
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
